.videoBlock {
    position: relative;
    height: max-content;
}

.videoImage {
    width: 100%;
    border-radius: 0.5rem;
}

.playButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.controlsBlock {
    position: absolute;
    bottom: 0.75rem;
    left: 0.75rem;
    width: calc(100% - 1.5rem);
    height: max-content;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    background: #000000B2;
    padding: 0.75rem;
    border-radius: 0.375rem;
}

.progressLine {
    position: relative;
    flex: 1;
    border: 1px solid #666666;
    height: 0.75rem;
    padding: 1px;
    cursor: pointer;
}

.viewedLine {
    position: absolute;
    left: 1px;
    top: 1px;
    height: calc(0.75rem - 4px);
    width: 5%;
    background: #FF9900;
    z-index: 1;
}

.downloadedLine {
    position: absolute;
    left: 1px;
    top: 1px;
    height: calc(0.75rem - 4px);
    width: 15%;
    background: #666666;
    z-index: 0;
}

.controlsButtons {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
}

.socialBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0.75rem 0;
    color: #737373;
    font-size: 14px;
}

.statisticsBlock, .shareBlock {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.statisticsItem {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    white-space: nowrap;
}

@media (max-width:  840px) {
	.statisticsBlock, .shareBlock {
		gap: 1rem;
	}
  }

@media (max-width: 600px) {
	.shareBlock {
		display: none;
	}

    /* .videoImage {
        height: 30rem;
        object-fit: cover;
    } */

    .socialBlock {
        padding: 1rem 0 0;
    }
  }