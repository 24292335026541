@import './fonts.css';

body {
	margin: 0;
	padding: 0;
	min-height: 100vh;
	font-family: Plus Jakarta Sans;
	background: #000000;
}

#app {
	max-width: 1600px;
	margin: 0 auto;
}

textarea, input { outline: none; border: none; }

div, p, a, input, h1, h2, h3, h4, h5 {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

a {
	text-decoration: none;
	color: white;
}

