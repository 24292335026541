.footer {
    padding: 2.5rem;
    background: #0F0F0F;
    color: white;
}

.footerContent {
    display: flex;
    justify-content: space-between;
    padding-bottom: 2rem;
    gap: 2rem;
}

.logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.description {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    max-width: 23.5rem;
}

.desktopDescription {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.mobileDescription {
    display: none;
}

.descriptionText {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.descriptionParagraph {
    font-size: 0.75rem;
    color: #737373;
}

.sponsors {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
}

.linksBlock {
    display: flex;
    flex-direction: column;
    min-width: 12.5rem;
}

.navLink {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 0;
    font-size: 0.75rem;
    border-top: 1px solid #FFFFFF0D;
    color: #737373;
    cursor: pointer;
}

.linksTitle {
    color: white;
    font-size: 0.75rem;
    margin-bottom: 1rem;
    font-weight: 700;
}

.rights {
    display: flex;
    justify-content: space-between;
    padding-top: 2rem;
    color: #737373;
    border-top: 1px solid #FFFFFF0D;
}

@media (max-width: 1024px) {
    .footerContent {
        flex-direction: column;
    }

    .description {
        max-width: 100%;
    }

    .desktopDescription {
        display: none;
    }

    .mobileDescription {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }

    .rights {
        display: none;
    }
  }

@media (max-width: 760px) {
	.footer {
		padding: 1rem;
	}
  }