.root {
    min-width: 220px;
    color: white;
    cursor: pointer;
    font-family: Apercu;
    object-fit: cover;
}

.image {
    width: 100%;
    height: auto;
    aspect-ratio: 1.9 / 1;
}

.descriptionBlock {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem 0;
}

.titleBlock {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.title {
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: left;
}

.description {
    font-size: 0.8125rem;
    line-height: 1rem;
    text-align: left;
    color: #737373;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.viewsBlock {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.viewsValue {
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.viewsValue p {
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: left;
}


