@font-face {
    font-family: 'Plus Jakarta Sans';
    font-weight: 400;
    src: url('../assets/fonts/PlusJakartaSans-VariableFont_wght.ttf') format('truetype');
  }

@font-face {
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    src: url('../assets/fonts/PlusJakartaSans-VariableFont_wght.ttf') format('truetype');
  }

@font-face {
    font-family: 'Apercu';
    font-weight: 400;
    src: url('../assets/fonts/apercu-regular.ttf') format('truetype');
  }

@font-face {
    font-family: 'Apercu';
    font-weight: 700;
    src: url('../assets/fonts/Apercu-Bold.ttf') format('truetype');
  }