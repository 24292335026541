.header {
	display: flex;
	align-items: center;
	gap: 2.5rem;
	justify-content: space-between;
	background: #000000;
	padding: 1.5rem 2.5rem;
}

.contentWrapper {
	display: flex;
	align-items: center;
	gap: 2.5rem;
	width: 100%;
}

.inputWrapper {
	position: relative;
	flex: 1;
	max-width: 46.875rem;
}

.searchIcon {
	position: absolute;
	top: 0.6875rem;
	left: 1rem;
}

.searchInput {
	width: 100%;
	height: 2.375rem;
	border-radius: 1rem;
	padding: 0 0 0 2.5rem;
	background-color: #151515;
	color: white;
}

.searchSettingsIcon {
	position: absolute;
	top: 0.6875rem;
	right: 1rem;
}

@media (max-width: 760px) {
	.header {
		padding: 0.75rem 1rem;
	}

	.inputWrapper {
		display: none;
	}
  }
