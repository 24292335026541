.root {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2.5rem;
    font-family: Apercu;
}

.title {
    color: white;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
}

.mainContentWrapper {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    gap: 1.5rem;
}

.leftRecomendationsBlock {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.rightRecomendationsBlock {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.bottomRecomendationBlock {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    justify-content: space-between;
    overflow: auto;
}

.commentsBlock {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    padding: 3rem 1.5rem;
    background: #0F0F0F;
}

.commentsCount {
    font-weight: 700;
    color: white;
    font-size: 1.5rem;
}

.commentsNotification {
    color: #737373;
    font-size: 1rem;
}

@media (max-width: 1180px) {
	.mainContentWrapper {
		grid-template-columns: 4fr 1fr;
	}

    .leftRecomendationsBlock {
        display: none;
    }
  }

@media (max-width: 840px) {
	.mainContentWrapper {
		grid-template-columns: 1fr;
	}

    .rightRecomendationsBlock {
        display: none;
    }
  }

@media (max-width: 600px) {
    .root {
        padding: 1rem;
    }

    .title {
        font-size: 1.125rem;
    }

	.bottomRecomendationBlock {
		flex-direction: column;
	}

    .commentsBlock {
        gap: 1.5rem;
        padding: 1.5rem;
    }

    .commentsCount {
        font-size: 1.125rem;
    }
  }