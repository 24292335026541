.root {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    height: max-content;
    background-repeat: no-repeat;
    background-size: cover;
}

.notificationContainer {
    position: relative;
    margin: 5rem auto;
    max-width: 90%;
    width: 64rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: center;
    border-radius: 0.5rem;
    padding: 2.5rem;
    background: black;
}

.title {
    font-size: 2.25rem;
    font-weight: 700;
    text-align: center;
    color: white;
}

.notificationText {
    font-size: 1.25rem;
    color: #737373;
    text-align: center;
    line-height: 1.5625rem;
    letter-spacing: 0.5px;
    max-width: 46.375rem;
}

.closeButton {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    color:#FF9900;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    cursor: pointer;
}

.buttonsBlock {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.confirmButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FF9900;
    color: black;
    width: 18.5rem;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    cursor: pointer;
    border: none;
}

.rejectButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1F1F1F;
    color: white;
    width: 18.5rem;
    padding: 0.5rem 1rem;
    border-radius: 0.375rem;
    cursor: pointer;
    border: none;
}

.parentalControl {
    cursor: pointer;
    color: white;
}

.rights {
    font-size: 0.75rem;
    color: #737373;
    padding-top: 2.5rem;
}

@media (max-width: 760px) {
	.buttonsBlock {
        flex-direction: column;
    }

    .title {
        font-size: 1.5rem;
    }

    .notificationContainer {
        margin: 2rem auto;
        gap: 2rem;
        padding: 1rem;
    }

    .notificationText {
        font-size: 1rem;
    }

    .rights {
        padding-top: 0;
    }
  }