.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #0F0F0F;
    padding: 0 2.5rem 2rem;
    gap: 1rem;
    overflow: auto;
}

.navItem {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #FFFFFF ;
    white-space: nowrap;
}

.selected {
    color: #FF9900;
    border-bottom: 1px solid #FF9900;
}

.notSelectedArrow {
    filter: brightness(0) invert(1);
}

@media (max-width: 760px) {
	.navbar {
		padding: 0 1rem 2rem;
	}
  }
    