.root {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    height: max-content;
    background-repeat: no-repeat;
    background-size: cover;
    background: black;
}

.blockWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid gray;
    border-radius: 1rem;
    padding: 2rem;

}

.notificationText {
    font-size: 1.5rem;
    color: white;
    max-width: 37.5rem;
    padding: 1rem;
    text-align: center;
}

.closeButton {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    width: 2rem;
    height: 2rem;
    padding-bottom: 0.4rem;
    border-radius: 50%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    line-height: 1rem;
    cursor: pointer;
}

.buttonsBlock {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.confirmButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: green;
    color: white;
    width: 6.25rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border: none;
}

.rejectButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: red;
    color: white;
    width: 6.25rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border: none;
}